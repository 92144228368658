import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Whole Grain Plant Based Tenders</title>
          <meta
            name="description"
            content="Raised & Rooted™ Whole Grain Plant Based Chicken Tenders are made with pea protein and have a crunchy whole grain breading. Try these meatless chicken tenders today!"
          />
          <meta itemProp="name" content="Whole Grain Plant Based Tenders" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Whole Grain Plant Based Tenders"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="Raised & Rooted™ Whole Grain Tenders are made with plant based protein and wrapped in crunchy, whole grain breading. Tasty on their own or in your favorite wraps and salads, they're waiting for you in the frozen aisle today."
              itemColor="#006B2D"
              valueText1="8"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="5"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="33"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="MADE WITH PLANT PROTEIN"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Than USDA Data for White Meat Nuggets **except for soy bean oil"
              footnoteColor="#006B2D"
              productID="00053400000071"
            >
              <img src="/raised-and-rooted-whole-grain-plant-based-tenders-708x721.png" alt="Raised And Rooted Whole Grain Plant Based Tenders" />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-whole-grain-plant-based-tenders-708x721-ls.png"
              altText="Raised And Rooted Whole Grain Plant Based Tenders"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Raised & Rooted™ Whole Grain Tenders are made with pea protein and golden flaxseed, and wrapped up in a crunchy, flavorful whole grain breading. Bring some plant based perfection to your favorite dishes today."
            >
              <h4>NUTRITION</h4>
              <p>
                8 grams of protein and 5 grams of fiber per serving. They also
                have 33% less saturated fat than a regular USDA white meat
                chicken tender but are 100% as tasty and versatile.
              </p>
              <h4>TASTE</h4>
              <p>
                These are some of the crispiest, most craveable tenders around.
                Dip them. Toss them in a salad. Drop them in a wrap. Or all of
                the above. You can’t go wrong with these deliciously
                plant-packed tenders.
              </p>
            </Content>
            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>HEATING INSTRUCTIONS</b>
                  <p>
                    Keep frozen until ready to use. Appliances vary. Heating
                    times approximate.
                  </p>
                  <b>Conventional OVEN</b>
                  <p>
                    Preheat oven to 400°F. Arrange tenders on a baking sheet
                    about 1-inch apart. Bake for 12-14 minutes or until hot.
                  </p>
                  <b>MICROWAVE</b>
                  <p>
                    Arrange 3 tenders on a microwave-safe plate. Microwave on
                    high for 75-90 seconds or until hot. Let stand for 1 minute,
                    before serving.
                  </p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>3 Servings Per Container</p>
                  <p>Serving Size 2 pieces (76g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 210</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 12g</td>
                      <td>15%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 5g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 3.5g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 480mg</td>
                      <td>21%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 17g</td>
                      <td>6%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 5g</td>
                      <td>18%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 1g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 0g Added Sugars</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Protein 8g</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0.3mcg</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td>Calcium 170mg</td>
                      <td>15%</td>
                    </tr>
                    <tr>
                      <td>Iron 1.8mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Potassium 120mg</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *Percent daily values are based on a 2,000 calorie diet.
                        Your daily values may be higher or lower depending on
                        your calorie needs.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                  SEASONED PLANTPROTEIN PORTION: VEGAN FIBERS (WATER, PEA
                  PROTEIN ISOLATE, CANOLA OIL, SODIUMALGINATE, CITRUS FIBER,
                  CALCIUM CHLORIDE), WATER, OAT FIBER, VITAL WHEAT GLUTEN, PEA
                  PROTEIN CONCENTRATE, SEASONING [MALTODEXTRIN (FROM CORN),
                  SALT, NATURAL FLAVOR, YEAST EXTRACT, PALM OIL, CARROT FIBER,
                  CITRIC ACID, MEDIUM CHAINTRIGLYCERIDES, ONION POWDER, TORULA
                  YEAST, ONION JUICE CONCENTRATE, SPICE, CANOLA OIL], FLAXSEED,
                  METHYLCELLULOSE, SALT, NATURAL FLAVORING (SUNFLOWER OIL AND
                  ROSEMARY EXTRACT). BREADED WITH: WHOLE WHEAT FLOUR, WHEAT
                  FLOUR, WATER, SALT, SOYBEAN OIL, YELLOW CORNFLOUR, WHEAT
                  GLUTEN, CORNSTARCH, SUGAR, DRIED YEAST, DRIED GARLIC, DRIED
                  ONION, SPICE, PAPRIKA EXTRACT. BREADING SET IN VEGETABLE OIL
                  (INCLUDING SOY BEAN OIL).
                </p>
                <H4 headingText="Allergens" />
                <ul className="allergens">
                  <li>Contains: WHEAT</li>
                </ul>
                <p>INGREDIENT(S) DERIVED FROM A BIOENGINEERED SOURCE</p>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000071" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
